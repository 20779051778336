import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GridWrapper } from 'src/components/GridWrapper';

const SMain = styled.div`
    margin-left: 0.8rem;
    flex: 3;

    & .gatsby-image-wrapper {
        height: 100%;
    }

    @media (max-width: 768px), (orientation: portrait) {
        margin-left: unset;
        margin-bottom: 0.8rem;
        order: 1;
    }
`;

const SColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;

    @media (max-width: 768px), (orientation: portrait) {
        order: 2;
    }
`;

const SUpperImage = styled(GatsbyImage)`
    margin-bottom: 0.8rem;
    height: 42%;
`;

const SLowerImage = styled(GatsbyImage)`
    height: 58%;
    flex: 1;
`;

export const Grid = ({ upper, lower, main, projectName }) => {
    return (
        <GridWrapper>
            <SColumn>
                <SUpperImage image={getImage(upper)} alt={projectName} width="100%" height="100%" />
                <SLowerImage image={getImage(lower)} alt={projectName} width="100%" height="100%" />
            </SColumn>
            <SMain>
                <GatsbyImage image={getImage(main)} alt={projectName} width="100%" height="100%" layout="fixed" />
            </SMain>
        </GridWrapper>
    );
};
