import React from 'react';
import styled from 'styled-components';

const SGridWrapper = styled.div`
    height: 100vh;
    display: flex;

    @media (max-width: 768px), (orientation: portrait) {
        flex-direction: column;
        height: unset;
    }
`;

export const GridWrapper = ({ children }) => {
    return <SGridWrapper>{children}</SGridWrapper>;
};
